import React from 'react';
import { Mail, Instagram, Music } from 'react-feather';

function Contact() {
  return (
    <div className="px-[5vw] mb-[3vh] min-w-full mb-2 items-center flex flex-col sm:block">
      {/* <h3 className="h3">Links</h3> */}
      <div className="flex gap-2 items-center">
        <a href="mailto:lakejkiwihousee@gmail.com" aria-label="Email">
          <Mail className="w-6 h-6 hover:text-[#ff6305] transform hover:rotate-12" />
        </a>
        <a href="https://www.instagram.com/lakejkiwihouse/" aria-label="Instagram">
          <Instagram className="w-6 h-6 hover:text-[#ff6305] transform hover:rotate-12" />
        </a>
        <a href="https://ffm.to/myownmess" aria-label="Music">
          <Music className="w-6 h-6 hover:text-[#ff6305] transform hover:rotate-12" />
        </a>
      </div>
    </div>
  );
}

export default Contact;
