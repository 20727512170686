import Dizzy from '../../assets/images/discography/dizzy.webp';
import MyOwnMess from '../../assets/images/discography/myOwnMess.webp';
import OftenMyMind from '../../assets/images/discography/oftenMyMind.webp';
import DownInHeaven from '../../assets/images/discography/downInHeaven.webp';
import WildOnion from '../../assets/images/discography/wildOnion.webp';
import Cell from '../../assets/images/discography/cell.webp';
import GirlLovesMe from '../../assets/images/discography/girlLovesMe.webp';
import KaraokeAlone from '../../assets/images/discography/karaokeAlone.webp';
import LookoutLow from '../../assets/images/discography/lookoutLow.webp';
import SideA from '../../assets/images/discography/sideA.webp';
import Stonerism from '../../assets/images/discography/stonerism.webp';
import Scout from '../../assets/images/discography/scout.webp';
import Sweet17 from '../../assets/images/discography/sweet17.webp';
import TossingTears from '../../assets/images/discography/tossingTears.webp';
import ShakeYourLonely from '../../assets/images/discography/shakeYourLonely.webp';
import ComeForMe from '../../assets/images/discography/comeForMe.webp';
import BlueCoupe from '../../assets/images/discography/blueCoupe.webp';
import WithYou from '../../assets/images/discography/withYou.webp';
import InTheMeadow from '../../assets/images/discography/inTheMeadow.webp';
import UrbsInHorto from '../../assets/images/discography/urbsInHorto.webp';
import Sunken from '../../assets/images/discography/sunken.webp';
import HoneySlides from '../../assets/images/discography/honeySlides.webp';
import TwentyTwenty from '../../assets/images/discography/twentyTwenty.webp';
import LookingOut from '../../assets/images/discography/lookingOut.webp';
import Mineral from '../../assets/images/discography/mineral.webp';
import TwoBirds from '../../assets/images/discography/twoBirds.webp';
import RainingInLa from '../../assets/images/discography/rainingInLa.webp';
import Daisies from '../../assets/images/discography/daisies.webp';
import BigAmigo from '../../assets/images/discography/bigAmigo.webp';
import Column from '../../assets/images/discography/column.webp';
import WhereverYouArent from '../../assets/images/discography/whereverYouArent.webp';
import EmotionallyAvailable from '../../assets/images/discography/emotionallyAvailable.webp';
import SyntheticFabrics from '../../assets/images/discography/syntheticFabrics.webp';
import WhereverYouArentSingle from '../../assets/images/discography/whereverYouArentSingle.webp';
import TheLevee from '../../assets/images/discography/theLevee.webp';
import WhenYouHear from '../../assets/images/discography/whenYouHear.webp';
import HouseOfTheButterflies from '../../assets/images/discography/houseOfTheButterflies.webp';
import NoOfferings from '../../assets/images/discography/noOfferings.webp';

const releases = [
  {
    artist: 'The Aubreys',
    title: 'Karaoke Alone',
    role: 'performance, production, mix',
    year: '2021',
    image: [KaraokeAlone],
    listen: 'https://open.spotify.com/album/6xhQCHLUYlSLDxOX83FnfK',
  },
  {
    artist: 'The Aubreys/Lunar Vacation',
    title: 'No Offerings',
    role: 'mix',
    year: '2021',
    image: [NoOfferings],
    listen: 'https://open.spotify.com/album/3RiPIwXRLN0ieTACLNjE4D',
  },
  {
    artist: 'Calpurnia',
    title: 'Scout',
    role: 'production, mix',
    year: '2018',
    image: [Scout],
    listen: 'https://open.spotify.com/album/4De7cz5QuNq6EoVc9Hb8Vh',
  },
  {
    artist: 'Calpurnia',
    title: 'Cell',
    role: 'production, mix',
    year: '2019',
    image: [Cell],
    listen: 'https://open.spotify.com/album/0XJPkgkPQQbHrkFQyxbSBO',
  },
  {
    artist: 'Column',
    title: 'I & II',
    role: 'songwriting, performance, production, design',
    year: '2019',
    image: [Column],
    listen: 'https://columnband.bandcamp.com/album/i-ii',
  },
  {
    artist: 'Djo',
    title: 'Twenty Twenty',
    role: 'drum engineer',
    year: '2019',
    image: [TwentyTwenty],
    listen: 'https://open.spotify.com/album/3kxxxUQ9HFVUSg2pDLYoZ0',
  },
  {
    artist: 'Elizabeth Moen',
    title: `Wherever You Aren't`,
    role: 'design',
    year: '2022',
    image: [WhereverYouArent, EmotionallyAvailable, SyntheticFabrics, WhereverYouArentSingle],
    listen: 'https://elizabethmoen.bandcamp.com/album/wherever-you-arent',
  },
  {
    artist: 'Finom',
    title: 'Girl Loves Me b/w Some Kind Of Calm',
    role: 'design',
    year: '2021',
    image: [GirlLovesMe],
    listen: 'https://finom.bandcamp.com/album/girl-loves-me-b-w-some-kind-of-calm',
  },
  {
    artist: 'Honey Slides',
    title: 'Skipping Stones into the Astroid Belt',
    role: 'design',
    year: '2020',
    image: [HoneySlides],
    listen:
      'https://honeyslides.bandcamp.com/track/skipping-stones-into-the-asteroid-belt-feat-dustin-laurenz',
  },
  {
    artist: 'Javier Reyes',
    title: 'Big Amigo',
    role: 'design',
    year: '2021',
    image: [BigAmigo],
    listen: 'https://javierreyes.bandcamp.com/album/big-amigo',
  },
  {
    artist: 'Lake J',
    title: 'Dizzy',
    role: 'songwriting, performance, production, mix, design',
    year: '2024',
    image: [Dizzy, MyOwnMess, OftenMyMind],
    listen: 'https://lakejons.bandcamp.com/album/lake-jons',
  },
  {
    artist: 'Lane Beckstrom',
    title: 'Looking Out',
    role: 'design',
    year: '2023',
    image: [LookingOut, Mineral, TwoBirds, RainingInLa, Daisies],
    listen: 'https://freshselects.bandcamp.com/album/looking-out',
  },
  {
    artist: 'R. Andrew Humphrey',
    title: 'House of the Butterflies',
    role: 'background vocals',
    year: '2022',
    image: [HouseOfTheButterflies],
    listen: 'https://open.spotify.com/album/2koijaMtrIofuMfvhVnnYy',  
  },
  {
    artist: 'Strange Faces',
    title: 'Stonerism',
    role: 'mix',
    year: '2015',
    image: [Stonerism],
    listen: 'https://open.spotify.com/album/0iufkhXARZO9J9OPUF2GH4',
  },
  {
    artist: 'Sun Cop',
    title: "The Levee",
    role: 'vocals ("Give Us What We Want")',
    year: '2019',
    image: [TheLevee],
    listen: 'https://suncop.bandcamp.com/album/the-levee',
  },
  {
    artist: 'Sun Cop',
    title: 'When You Hear the Drum You May Speak',
    role: 'vocals ("Golden Son")',
    year: '2017',
    image: [WhenYouHear],
    listen: 'https://suncop.bandcamp.com/album/when-you-hear-the-drum-you-may-speak',
  },
  {
    artist: 'Twin Peaks',
    title: 'Sunken',
    role: 'songwriting, performance, production',
    year: '2013',
    image: [Sunken],
    listen: 'https://twinpeakschicago.bandcamp.com/album/sunken',
  },
  {
    artist: 'Twin Peaks',
    title: 'Wild Onion',
    role: 'songwriting, performance, production, design',
    year: '2014',
    image: [WildOnion],
    listen: 'https://twinpeakschicago.bandcamp.com/album/wild-onion',
  },
  {
    artist: 'Twin Peaks',
    title: 'Down in Heaven',
    role: 'songwriting, performance, production, design',
    year: '2016',
    image: [DownInHeaven],
    listen: 'https://twinpeakschicago.bandcamp.com/album/down-in-heaven',
  },
  {
    artist: 'Twin Peaks',
    title: 'Urbs in Horto',
    role: 'songwriting, performance, production, mix, design',
    year: '2017',
    image: [UrbsInHorto],
    listen: 'https://twinpeakschicago.bandcamp.com/album/urbs-in-horto',
  },
  {
    artist: 'Twin Peaks',
    title: "Sweet '17 Singles",
    role: 'songwriting, performance, production, mix, design',
    year: '2017',
    image: [Sweet17, TossingTears, ShakeYourLonely, ComeForMe, BlueCoupe, WithYou, InTheMeadow],
    listen: 'https://twinpeakschicago.bandcamp.com/album/sweet-17-singles',
  },
  {
    artist: 'Twin Peaks',
    title: 'Lookout Low',
    role: 'songwriting, performance, design',
    year: '2019',
    image: [LookoutLow],
    listen: 'https://twinpeakschicago.bandcamp.com/album/lookout-low',
  },
  {
    artist: 'Twin Peaks',
    title: 'Side A',
    role: 'songwriting, performance, production, design',
    year: '2020',
    image: [SideA],
    listen: 'https://twinpeakschicago.bandcamp.com/album/side-a',
  },
];

export default releases;
