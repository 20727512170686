import React from 'react';
function KiwiHouseIcon({ className }) {
  return (
    <svg
      className={`fill-[#ff6305] ${className}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(-50,800) scale(0.100000,-0.100000)"
        fill='#1d4ed8'
        opacity={.8}
      >
        {' '}
        {/* ROOF */}
        <path
          d="M4343 7438 c-65 -62 -162 -155 -217 -208 -54 -52 -104 -99 -110 -105
            -6 -5 -58 -55 -116 -110 -58 -55 -188 -179 -289 -275 -101 -96 -202 -193 -225
            -215 -23 -22 -120 -114 -216 -205 -154 -145 -270 -255 -414 -395 -22 -22 -84
            -80 -136 -130 -119 -112 -253 -240 -496 -471 -104 -100 -202 -193 -219 -208
            -42 -39 -379 -361 -498 -477 -91 -88 -98 -98 -87 -118 14 -28 53 -46 73 -35
            18 11 117 102 247 228 101 97 209 201 514 491 104 99 201 191 215 205 14 14
            69 65 121 115 87 82 314 298 594 565 61 58 152 144 201 190 50 47 108 103 131
            125 23 22 124 119 225 215 101 96 231 220 289 275 58 55 110 105 116 110 6 6
            97 93 203 195 106 102 203 189 217 194 15 6 33 6 48 0 14 -5 111 -92 217 -194
            209 -200 375 -359 508 -485 47 -44 104 -98 127 -120 401 -386 458 -438 468
            -428 3 3 6 155 6 337 0 191 4 337 10 346 8 13 61 15 355 18 232 2 351 -1 365
            -8 20 -11 20 -20 20 -701 0 -659 1 -691 19 -727 18 -34 109 -127 346 -352 50
            -47 178 -169 285 -271 248 -237 329 -312 347 -323 20 -11 59 7 73 35 14 24 4
            34 -370 389 -102 96 -237 225 -300 286 -63 60 -144 137 -178 170 -111 103
            -102 27 -102 849 0 642 -2 718 -16 740 l-16 25 -458 0 c-452 0 -457 0 -476
            -21 -17 -19 -19 -41 -22 -253 -2 -127 -7 -235 -12 -240 -17 -17 -60 5 -112 55
            -29 28 -89 85 -133 128 -44 42 -165 158 -270 256 -104 99 -202 191 -216 205
            -211 203 -401 383 
            -430 409 -59 51 -73 45 -206 -81z"
        />
        <g
          className="animate-spinslow direction-reverse absolute origin-center"
          style={{
            transformOrigin: 'center',
            transformBox: 'fill-box',
            transform: 'translate(-50%, -50%) rotate(45deg) translate(50%, 50%)',
          }}
          fill='#90C825'
        >
          {/* CIRCLE */}
          <path
            d="M4225 5554 c-153 -24 -298 -59 -372 -91 -17 -7 -37 -13 -45 -13 -8 0
                -23 -7 -34 -15 -10 -8 -25 -15 -32 -15 -19 0 -169 -77 -237 -120 -16 -11 -37
                -23 -45 -27 -33 -15 -210 -154 -284 -224 -123 -115 -246 -271 -343 -434 -14
                -24 -80 -158 -98 -200 -38 -90 -45 -107 -45 -120 0 -8 -6 -29 -14 -47 -15 -35
                -33 -104 -62 -233 -15 -65 -18 -127 -18 -330 0 -256 5 -301 50 -461 8 -28 14
                -56 14 -63 0 -7 6 -27 14 -44 8 -18 16 -41 19 -52 43 -159 206 -441 351 -607
                377 -430 876 -658 1437 -658 203 0 449 39 602 96 95 36 126 48 184 75 35 16
                65 29 67 29 2 0 42 23 88 51 46 27 92 56 103 62 64 39 189 142 274 225 118
                116 182 193 267 322 60 92 164 288 164 309 0 6 6 25 14 43 27 63 44 113 59
                173 9 33 24 89 34 125 15 56 18 106 18 370 0 321 2 303 -51 505 -27 101 -62
                190 -131 332 -200 412 -567 751 -993 918 -25 9 -56 21 -68 26 -59 23 -143 49
                -158 49 -10 0 -40 6 -68 14 -153 44 -478 58 -661 30z m545 -120 c209 -40 362
                -93 536 -184 340 -180 600 -450 773 -803 34 -69 61 -130 61 -136 0 -5 6 -25
                14 -43 25 -58 72 -237 81 -308 3 -25 10 -74 15 -110 12 -82 12 -244 0 -335 -5
                -38 -12 -90 -15 -115 -9 -71 -56 -250 -81 -308 -8 -18 -14 -37 -14 -43 0 -16
                -89 -196 -137 -277 -135 -229 -364 -459 -597 -601 -73 -44 -269 -141 -286
                -141 -5 0 -24 -6 -42 -14 -137 -58 -410 -107 -601 -106 -192 0 -458 48 -595
                106 -18 8 -37 14 -42 14 -17 0 -221 101 -290 143 -116 71 -247 176 -343 277
                -88 91 -217 250 -217 266 0 4 -6 15 -14 23 -12 14 -50 81 -92 161 -31 60 -74
                161 -74 175 0 8 -6 29 -14 47 -70 163 -108 470 -87 704 17 197 44 315 117 507
                50 131 188 368 275 472 98 118 354 344 429 378 8 4 30 16 49 28 19 11 66 36
                105 56 39 19 79 39 88 44 67 34 270 95 408 122 111 22 478 22 590 1z"
          />{' '}
          {/* KIWIS */}
          <path
            d="M4685 4856 c-20 -30 -23 -119 -5 -140 19 -23 63 -20 89 7 26 29 34
                114 12 140 -21 26 -77 22 -96 -7z"
          />
          <path
            d="M4201 4854 c-20 -15 -23 -23 -19 -66 4 -36 13 -57 33 -78 34 -35 44
-37 73 -10 46 43 15 170 -41 170 -13 0 -33 -7 -46 -16z"
          />
          <path
            d="M3821 4704 c-27 -34 -27 -33 8 -80 73 -102 146 -89 128 23 -13 78
-93 112 -136 57z"
          />
          <path
            d="M4431 4704 c-25 -32 -26 -60 -5 -113 17 -44 42 -58 72 -42 23 12 52
70 52 104 0 37 -36 77 -70 77 -19 0 -36 -9 -49 -26z"
          />
          <path
            d="M5090 4690 c-42 -42 -53 -115 -21 -144 18 -16 21 -16 57 1 43 20 85
83 77 114 -13 47 -79 63 -113 29z"
          />
          <path
            d="M4274 4494 c-22 -21 -25 -31 -19 -62 9 -51 50 -102 80 -102 44 0 67
72 44 139 -19 55 -65 66 -105 25z"
          />
          <path
            d="M4584 4495 c-22 -34 -30 -101 -14 -130 28 -53 80 -39 111 28 25 55
24 79 -4 105 -32 30 -72 29 -93 -3z"
          />
          <path
            d="M3746 4416 c-34 -34 -33 -48 11 -96 34 -38 40 -41 82 -38 49 3 59 16
47 62 -8 32 -58 83 -88 91 -21 5 -33 1 -52 -19z"
          />
          <path
            d="M5124 4407 c-45 -41 -59 -108 -26 -126 45 -24 142 37 142 88 0 32 -8
45 -37 60 -32 16 -40 14 -79 -22z"
          />
          <path
            d="M4002 4377 c-12 -13 -22 -31 -22 -41 0 -50 85 -128 124 -113 25 10
38 54 27 95 -21 76 -86 105 -129 59z"
          />
          <path
            d="M4873 4378 c-46 -51 -57 -112 -25 -141 27 -24 44 -21 86 16 45 39 59
96 31 127 -24 27 -67 25 -92 -2z"
          />
          <path
            d="M3461 4344 c-29 -37 -26 -60 10 -93 34 -32 88 -45 125 -31 47 18 22
89 -48 133 -38 24 -63 21 -87 -9z"
          />
          <path
            d="M5435 4340 c-11 -4 -33 -21 -48 -38 -59 -62 -10 -130 70 -97 80 34
104 82 60 123 -24 23 -48 26 -82 12z"
          />
          <path
            d="M4427 4302 c-27 -29 -31 -75 -11 -117 35 -75 90 -67 113 18 13 44 3
93 -22 109 -22 14 -64 8 -80 -10z"
          />
          <path
            d="M4212 4257 c-30 -32 -28 -66 7 -114 37 -51 65 -59 97 -28 29 29 29
56 1 115 -18 37 -28 46 -52 48 -22 2 -37 -4 -53 -21z"
          />
          <path
            d="M4663 4251 c-27 -36 -37 -88 -24 -124 24 -62 116 -9 127 74 5 35 2
49 -11 62 -26 27 -68 21 -92 -12z"
          />
          <path
            d="M3793 4171 c-35 -29 -31 -81 6 -109 44 -33 101 -38 131 -14 l25 20
-20 38 c-19 35 -81 84 -107 84 -7 -1 -23 -9 -35 -19z"
          />
          <path
            d="M5104 4160 c-31 -12 -74 -68 -74 -96 0 -32 40 -58 78 -49 41 9 102
62 102 89 0 27 -39 66 -66 65 -10 0 -28 -4 -40 -9z"
          />
          <path
            d="M4036 4139 c-14 -11 -26 -31 -26 -43 0 -44 69 -106 118 -106 10 0 27
9 36 19 16 17 16 24 4 57 -8 23 -28 49 -52 66 -47 34 -46 33 -80 7z"
          />
          <path
            d="M4826 4120 c-29 -32 -36 -48 -36 -80 0 -29 5 -42 20 -50 41 -22 140
52 140 104 0 28 -39 66 -67 66 -11 0 -37 -18 -57 -40z"
          />
          <path
            d="M3307 3993 c-45 -51 -24 -102 50 -122 71 -20 140 39 101 86 -16 20
-86 52 -114 53 -11 0 -28 -8 -37 -17z"
          />
          <path
            d="M5570 3980 c-35 -8 -80 -50 -80 -74 0 -30 40 -50 96 -50 63 1 94 22
94 64 0 52 -42 75 -110 60z"
          />
          <path
            d="M3903 3968 c-42 -52 -36 -82 21 -117 46 -29 96 -27 125 3 23 24 23
25 5 55 -31 53 -127 90 -151 59z"
          />
          <path
            d="M4973 3953 c-26 -5 -73 -57 -73 -81 0 -35 18 -46 72 -46 92 0 145 59
97 110 -22 23 -44 27 -96 17z"
          />
          <path
            d="M3666 3895 c-9 -13 -16 -33 -16 -43 0 -43 71 -71 144 -58 92 18 31
126 -72 126 -31 0 -43 -5 -56 -25z"
          />
          <path
            d="M5193 3892 c-32 -10 -73 -49 -73 -70 0 -34 32 -52 93 -52 72 0 103
24 93 74 -10 50 -49 67 -113 48z"
          />
          <path
            d="M3470 3743 c-22 -9 -30 -24 -30 -59 0 -44 27 -64 87 -64 39 0 54 5
71 23 46 48 16 95 -64 102 -27 3 -56 2 -64 -2z"
          />
          <path
            d="M3873 3740 c-38 -16 -47 -46 -26 -83 17 -29 24 -32 74 -35 46 -2 58
1 82 23 33 31 34 45 4 73 -26 25 -100 37 -134 22z"
          />
          <path
            d="M4974 3736 c-28 -13 -44 -33 -44 -57 0 -6 12 -21 26 -34 35 -32 121
-36 147 -7 18 19 22 59 9 78 -22 34 -85 43 -138 20z"
          />
          <path
            d="M5381 3743 c-25 -10 -52 -44 -49 -61 9 -44 111 -77 159 -51 43 23 40
101 -4 113 -27 7 -87 6 -106 -1z"
          />
          <path
            d="M3688 3594 c-72 -38 -26 -136 55 -119 47 10 97 51 97 79 0 49 -92 73
-152 40z"
          />
          <path
            d="M5135 3570 c-38 -42 18 -99 99 -102 54 -3 76 16 76 63 0 40 -33 59
-101 59 -43 0 -60 -5 -74 -20z"
          />
          <path
            d="M3943 3532 c-32 -10 -73 -49 -73 -70 0 -27 37 -62 65 -62 36 0 73 17
100 46 28 30 32 56 11 73 -18 15 -74 22 -103 13z"
          />
          <path
            d="M3313 3513 c-17 -11 -23 -25 -23 -54 0 -71 70 -89 147 -36 65 44 30
108 -59 106 -24 0 -53 -7 -65 -16z"
          />
          <path
            d="M4912 3509 c-30 -32 -13 -70 48 -108 50 -30 82 -27 109 10 30 40 18
70 -38 99 -52 26 -95 26 -119 -1z"
          />
          <path
            d="M5500 3475 l-24 -25 21 -28 c27 -36 59 -52 110 -52 74 0 100 71 39
109 -49 30 -116 28 -146 -4z"
          />
          <path
            d="M4086 3359 c-79 -42 -98 -102 -45 -142 26 -18 28 -19 56 -2 61 36 92
115 57 149 -20 20 -22 20 -68 -5z"
          />
          <path
            d="M4797 3363 c-4 -3 -7 -25 -7 -49 0 -36 5 -47 40 -78 46 -41 59 -43
91 -18 44 36 23 104 -43 138 -29 15 -70 19 -81 7z"
          />
          <path
            d="M5030 3331 c-24 -46 6 -102 67 -128 43 -18 69 -10 88 27 20 39 3 75
-47 101 -49 25 -94 25 -108 0z"
          />
          <path
            d="M3848 3326 c-40 -18 -78 -57 -78 -81 0 -25 39 -65 64 -65 26 0 72 27
95 56 30 38 28 78 -4 92 -31 14 -42 14 -77 -2z"
          />
          <path
            d="M4248 3233 c-14 -16 -32 -45 -39 -65 -12 -35 -11 -39 11 -63 13 -14
34 -25 46 -25 52 0 98 93 75 153 -14 37 -58 37 -93 0z"
          />
          <path
            d="M4639 3239 c-6 -12 -9 -42 -6 -66 9 -79 72 -118 117 -73 23 23 24 32
10 79 -20 72 -94 109 -121 60z"
          />
          <path
            d="M4448 3213 c-24 -28 -38 -59 -38 -87 0 -85 88 -117 125 -46 21 40 19
72 -8 111 -26 39 -56 48 -79 22z"
          />
          <path
            d="M5362 3148 c-36 -36 12 -119 78 -133 43 -9 80 16 80 55 0 59 -118
118 -158 78z"
          />
          <path
            d="M3524 3143 c-32 -6 -84 -59 -84 -86 0 -28 38 -67 66 -67 44 0 114 70
114 114 0 19 -7 29 -26 36 -26 10 -33 10 -70 3z"
          />
          <path
            d="M4850 3143 c-40 -16 -40 -76 0 -131 36 -50 64 -54 105 -17 34 32 28
66 -18 111 -34 34 -62 46 -87 37z"
          />
          <path
            d="M4049 3101 c-47 -46 -53 -91 -16 -122 34 -29 62 -23 96 18 23 28 31
48 31 78 0 76 -50 88 -111 26z"
          />
          <path
            d="M3813 3087 c-35 -13 -83 -70 -83 -99 0 -25 36 -58 63 -58 14 0 39 15
60 37 30 29 37 44 37 74 0 28 -5 41 -19 49 -11 5 -21 10 -23 9 -2 0 -17 -6
-35 -12z"
          />
          <path
            d="M5073 3079 c-22 -23 -14 -61 23 -105 41 -51 80 -58 114 -22 28 30 25
49 -12 92 -25 28 -43 39 -73 43 -25 3 -45 0 -52 -8z"
          />
          <path
            d="M4312 3027 c-28 -30 -44 -83 -36 -120 6 -27 35 -47 70 -47 41 0 75
91 53 147 -18 49 -53 57 -87 20z"
          />
          <path
            d="M4575 3020 c-45 -87 20 -192 90 -146 33 22 40 55 20 102 -33 79 -82
99 -110 44z"
          />
          <path
            d="M4448 2823 c-38 -44 -48 -91 -27 -137 21 -46 90 -43 114 5 21 39 19
71 -8 110 -26 39 -56 48 -79 22z"
          />
          <path
            d="M5024 2815 c-21 -32 -12 -84 22 -125 32 -38 71 -40 103 -6 31 33 22
67 -28 118 -45 44 -74 48 -97 13z"
          />
          <path
            d="M3863 2817 c-70 -39 -92 -131 -37 -156 38 -17 72 -3 100 40 29 46 31
90 6 112 -22 20 -39 21 -69 4z"
          />
          <path
            d="M4662 2667 c-16 -17 -22 -36 -22 -69 0 -83 62 -126 111 -77 31 31 20
104 -21 142 -37 33 -41 33 -68 4z"
          />
          <path
            d="M4245 2648 c-37 -33 -47 -116 -16 -147 50 -50 111 0 111 92 0 74 -43
99 -95 55z"
          />
        </g>
        {/* HOUSE */}
        <path
          d="M2290 5170 c-20 -20 -20 -33 -20 -1864 l0 -1845 26 -20 27 -21 2167
0 2167 0 27 21 26 20 0 1845 c0 1831 0 1844 -20 1864 -11 11 -29 20 -40 20
-11 0 -29 -9 -40 -20 -20 -20 -20 -33 -20 -1820 l0 -1800 -26 -10 c-35 -14
-4113 -14 -4148 0 l-26 10 0 1800 c0 1787 0 1800 -20 1820 -11 11 -29 20 -40
20 -11 0 -29 -9 -40 -20z"
        />
      </g>
    </svg>
  );
}

export default KiwiHouseIcon;
