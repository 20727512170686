import React from 'react';
import ShowItem from '../individualComponents/ShowItem'; // Import a new component for individual show item
import shows from '../data/Shows';

function Shows() {
  
  // Separate shows into upcoming and past categories
  const upcomingShows = [];
  const pastShows = [];
  
  const normalizeDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const currentDate = normalizeDate(new Date());

  shows.forEach((show) => {
    const showDate = normalizeDate(new Date(show.date));
    if (showDate >= currentDate) {
      upcomingShows.push(show);
    } else {
      pastShows.push(show);
    }
  });

  // Helper function to group shows by year
  const groupShowsByYear = (shows) => {
    return shows.reduce((acc, show) => {
      const year = new Date(show.date).getFullYear().toString();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(show);
      return acc;
    }, {});
  };

  // Function to render shows for a given category (upcoming/past)
  const renderShows = (shows, category) => {
    const groupedShows = groupShowsByYear(shows);
    const sortedYears = Object.keys(groupedShows).sort((a, b) => b - a);
    return (
      shows.length > 0 && (
        <div className="pl-4">
          <h2 className="text-xl font-montserrat font-medium uppercase pb-6">{category}:</h2>
          {sortedYears.map((year) => (
            <div key={year} className="pb-4">
              <h3 className="font-montserrat font-normal">{year}</h3>
              {groupedShows[year].reverse().map((show, index) => (
                <ShowItem key={index} show={show} />
              ))}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className="px-[5vw] mt-[1vh] space-y-8 mb-16">
      <div className="max-w-screen space-y-4">
        <p className="font-montserrat text-sm font-thin px-4 tracking-tighter">
          For booking inquiries, you can email me at{' '}
          <a
            className="lowercase font-montserrat text-sm font-thin no-underline hover:text-blue-700/80 hover:italic hover:font-normal cursor-pointer"
            href="mailto:lakejkiwihousee@gmail.com"
          >
            lakejkiwihouse@gmail.com
          </a>
        </p>
        {renderShows(upcomingShows, 'Upcoming')}
        {renderShows(pastShows, 'Past')}
      </div>
    </div>
  );
}

export default Shows;
