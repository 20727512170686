import React from 'react';
import { Link } from 'react-router-dom';
import KiwiHouseIcon from '../individualComponents/KiwiHouseIcon';

function LandingPage() {
  return (
    <div className="animate-enter w-1/4 mx-auto my-auto">
      <Link to="/lake-j">
        {/* <ReelToReel /> */}
        <KiwiHouseIcon />
        <div className="flex font-montserrat flex flex-col items-center tracking-tight justify-center gap-2 text-xl md:text-2xl lg:text-3xl lg:mt-[-40px] mt-[-15px]">
          <div className="uppercase font-normal italic flex whitespace-nowrap text-[#90C825]">
            KIWI HOUSE
          </div>
          <div className="lowercase font-extralight flex whitespace-nowrap text-[#1d4ed8] opacity-80">
            + Lake J
          </div>
        </div>
      </Link>
    </div>
  );
}

export default LandingPage;
