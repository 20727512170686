const episodes = [
  {
    date: 'April 15, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-with-cadien-lake-jack-henry-james-41523%2F',
    title: 'Kiwi House April 2023',
  },
  {
    date: 'May 20, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-51923%2F',
    title: 'Kiwi House May 2023',
  },
  {
    date: 'June 17, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-61723%2F',
    title: 'Kiwi House June 2023',
  },
  {
    date: 'July 15, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-71523%2F',
    title: 'Kiwi House July 2023',
  },
  {
    date: 'August 19, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-81923%2F',
    title: 'Kiwi House August 2023',
  },
  {
    date: 'September 16, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-71623%2F',
    title: 'Kiwi House September 2023',
  },
  {
    date: 'October 21, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-102123%2F',
    title: 'Kiwi House October 2023',
  },
  {
    date: 'November 18, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-111823%2F',
    title: 'Kiwi House November 2023',
  },
  {
    date: 'December 16, 2023',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-121623%2F',
    title: 'Kiwi House December 2023',
  },
  {
    date: 'January 20, 2024',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-12024%2F',
    title: 'Kiwi House January 2024',
  },
  {
    date: 'March 16, 2024',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-31624%2F',
    title: 'Kiwi House March 2024',
  },
  {
    date: 'May 18, 2024',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-51824%2F',
    title: 'Kiwi House May 2024',
  },
  {
    date: 'June 15, 2024',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-61524%2F',
    title: 'Kiwi House June 2024',
  },
  {
    date: 'July 20, 2024',
    link: 'https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbelovedwtf%2Fbeloved-radio-kiwi-house-72024%2F',
    title: 'Kiwi House July 2024',
  }
];

export default episodes;
