import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import releases from '../data/Releases';
import LazyImage from '../individualComponents/LazyImage';

function Music() {
  const groupReleasesByYear = (releases) => {
    return releases.reduce((acc, release) => {
      const year = release.year;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(release);
      return acc;
    }, {});
  };

  const releasesByYear = groupReleasesByYear(releases);
  const sortedYears = Object.keys(releasesByYear).sort((a, b) => b - a);

  return (
    <div className="px-[5vw] mt-[1vh] space-y-8 mb-16">
      <div className="max-w-screen space-y-4">
        {sortedYears.map((year) => (
          <YearSection key={year} year={year} releases={releasesByYear[year]} />
        ))}
      </div>
    </div>
  );
}

function YearSection({ year, releases }) {
  return (
    <div className="md:flex md:gap-4">
      <div className="md:w-6 bg-sky-500/70 text-white font-montserrat tracking-wide font-thin md:pb-8 px-2 md:px-0 rounded-t-md md:rounded-l-md md:rounded-tr-none relative">
        <div className="md:sticky md:top-0 md:translate-y-full md:-rotate-90">{year}</div>
      </div>
      <ReleaseList releases={releases} />
    </div>
  );
}

function ReleaseList({ releases }) {
  return (
    <ol className="divide-y divide-black/10 w-full flex flex-col mt-2 md:grid md:grid-cols-3">
      {releases.map((release, index) => (
        <li key={index}>
          <Release release={release} />
        </li>
      ))}
    </ol>
  );
}

function Release({ release }) {
  return (
    <a
      href={release.listen}
      target="_blank"
      rel="noopener noreferrer"
      className="grid h-full items-center py-2 px-4 transition-all hover:bg-sky-500/70 hover:text-white hover:rounded-md hover:px-2"
      aria-label={`Listen to ${release.title} by ${release.artist}`}
    >
      <div
        className="w-full"
        onClick={(event) => (release.image.length > 1 ? event.preventDefault() : null)}
      >
        {release.image && release.image.length > 1 ? (
          <Carousel showThumbs={false} infiniteLoop={true}>
            {release.image.map((image, index) => (
              <div key={index}>
                <LazyImage src={image} alt={`${release.title} album art ${index}`} />
              </div>
            ))}
          </Carousel>
        ) : (
          <LazyImage src={release.image} alt={`${release.title} album art`} />
        )}
      </div>
      <div className="pl-2 sm:pl-4 pr-2 w-full flex-grow font-montserrat">
        <strong className="font-semibold text-sm">{release.artist}</strong>
        <span className="inline-block italic font-light text-sm pl-2">{release.title}</span>
      </div>
      <div className="lowercase text-xs text-end font-montserrat font-extralight">
        {release.role}
      </div>
    </a>
  );
}

export default Music;
