import { Link, useLocation } from 'react-router-dom';
import ReelToReel from './individualComponents/ReelToReel';
import KiwiHouseIcon from './individualComponents/KiwiHouseIcon';

function Header() {
  const location = useLocation();
  const landingPage = location.pathname === '/';
  const homePage = location.pathname === '/lake-j';
  const kiwiHousePage = location.pathname === '/kiwi-house';

  const reelToReel = () => (
    <Link to="/lake-j">
      <ReelToReel className="sm:w-20 w-12" />
    </Link>
  );

  const kiwiHouse = () => (
    <div className="-ml-4 sm:-mt-4 -mt-1">
      <Link to="/lake-j">
        <KiwiHouseIcon className="sm:w-28 w-20" />
      </Link>
    </div>
  );

  const pageName = () => (
    <div
      className={`sm:text-3xl ${
        kiwiHousePage
          ? 'text-[#90C825] uppercase tracking-tight font-light sm:mb-2 -ml-4'
          : 'text-[#ff6305] italic font-extralight tracking-wider'
      } pt-6 ${!kiwiHousePage && 'ml-8'}`}
    >
      {location.pathname.split('/').pop().replace(/-/g, ' ')}
    </div>
  );

  return (
    <div className="p-[3vw] -indent-4 px-[5vw] font-montserrat">
      <div className={`flex ${kiwiHousePage && 'w-full sm:justify-center sm:-ml-12'} items-center gap-6 sm:gap-10 text-sm sm:text-base`}>
        {!landingPage && !kiwiHousePage && reelToReel()}
        {kiwiHousePage && kiwiHouse()}
        <div
          className={`items-center pt-2 ${
            homePage
              ? 'middle:flex middle:justify-between w-screen sm:whitespace-nowrap'
              : 'flex flex-nowrap sm:justify-between'
          }`}
        >
          {!landingPage && !kiwiHousePage && (
            <Link to="/lake-j">
              <h1 className="lowercase text-blue-700 opacity-80 sm:text-3xl font-light tracking-wider relative pt-6">
                Lake J
              </h1>
            </Link>
          )}
          {!landingPage && !homePage && pageName()}
        </div>
      </div>
    </div>
  );
}

export default Header;
