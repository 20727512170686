import React from 'react';

function ShowItem({ show }) {
  return (
    <a href={((new Date(show.date) >= new Date()) && show.tickets) ?? undefined} key={show.date} className={`items-center ${((new Date(show.date) >= new Date())  && show.tickets) && 'hover:text-blue-700'}`}>
    <div className="items-center py-2 px-4 mx-4 font-montserrat font-light">
      <div className="w-full flex flex-wrap items-center gap-2">
        <div className="flex gap-2">
          <div className="text-xs sm:text-sm whitespace-nowrap">
            {show.date.split('/').slice(0, 2).join('/')} —{' '}
          </div>
          <div className="text-xs sm:text-sm whitespace-nowrap">{show.city}</div>
          <div className="text-xs sm:text-sm whitespace-nowrap">@ {show.venue}</div>
        </div>
        {show.with && (
          <div className="flex text-xs sm:text-sm whitespace-nowrap italic">
            (with {show.with})
          </div>
        )}
      </div>
    </div>
    </a>
  );
}

export default ShowItem;
