import React, { useState, useEffect, useRef } from 'react';

function LazyImage({ src, alt }) {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const imgRefCurrent = imgRef.current;
    const observerRefCurrent = observerRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (observerRefCurrent && imgRefCurrent) {
            observerRefCurrent.unobserve(imgRefCurrent); // Stop observing when image is in view
          }
        }
      });
    });

    observerRef.current = observer;

    if (imgRefCurrent && observerRef.current) {
      observerRef.current.observe(imgRefCurrent);
    }

    return () => {
      if (observerRefCurrent && imgRefCurrent) {
        observerRefCurrent.unobserve(imgRefCurrent); // Stop observing when component unmounts
      }
    };
  }, []);

  return (
    <div ref={imgRef}>
      {isVisible && (
        <img
          src={src}
          alt={alt}
          style={{ transition: 'opacity 0.5s', opacity: isVisible ? 1 : 0 }}
          loading="lazy"
        />
      )}
    </div>
  );
}

export default LazyImage;
