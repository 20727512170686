import React, { useEffect, useState } from 'react';
import Nav from '../individualComponents/Nav';
import Contact from '../individualComponents/Contact';
import Photobooth from '../../assets/images/cade photobooth.webp';

function Home() {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const adjustOpacity = () => {
      const windowWidth = window.innerWidth;
      const maxWidth = 1200; // Tailwind's "max-w-[1600px]" breakpoint
      const smBreakpoint = 640; // Tailwind's "sm:" breakpoint

      let newOpacity = windowWidth / maxWidth;

      if (windowWidth <= smBreakpoint) {
        newOpacity = 1;
      }

      setOpacity(newOpacity);
    };

    adjustOpacity();

    window.addEventListener('resize', adjustOpacity);

    return () => {
      window.removeEventListener('resize', adjustOpacity);
    };
  }, []);

  return (
    <div className="animate-enter flex flex-col justify-between box-border">
      <Nav />
      <div
        className="px-[5vw] max-w-[80vw] -z-10 pb-12 ml-[10vw] sm:block sm:absolute sm:right-0 sm:top-1/2 sm:-translate-y-1/2 sm:mt-[3vh]"
        style={{ opacity }}
      >
        <img
          src={Photobooth}
          className="max-h-[70vh] mix-blend-overlay saturate-75 opacity-85 rounded-3xl border-8 border-[#ff6305] border-double"
          alt="Portrait of Cadien"
        />
      </div>
      <Contact />
    </div>
  );
}

export default Home;
