import { useLocation, Link } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const isLandingPage = location.pathname === '/';
  const currentYear = new Date().getFullYear();

  return isLandingPage ? null : (
    <footer
      id="site-footer"
      className={`text-xs p-[5vw] flex justify-center sm:justify-start sm:w-full sm:mt-auto`}
    >
      <div className="opacity-40 font-montserrat font-light">
        <span>© {currentYear} kiwi house</span>
        {!isHomePage && (
          <>
            {' | '}
            <Link to="/" className="underline">
              Home
            </Link>
          </>
        )}
      </div>
    </footer>
  );
}

export default Footer;
