const shows = [
  {
    date: '02/26/2022',
    venue: 'Metro',
    city: 'Chicago, IL',
    with: 'Liam Kazar',
  },
  {
    date: '08/13/2022',
    venue: 'Avrom Farm Party',
    city: 'Ripon, WI',
  },
  {
    date: '07/07/2023',
    venue: 'Thalia Hall',
    city: 'Chicago, IL',
    with: 'Spun Out & Cabeza de Chivo',
  },
  {
    date: '08/20/2023',
    venue: 'Glenwood Avenue Arts Fest',
    city: 'Chicago, IL',
  },
  {
    date: '08/26/2023',
    venue: 'Codfish Hollow',
    city: 'Maquoketa, IA',
    with: 'The Nude Party',
  },
  {
    date: '08/29/2023',
    venue: 'Backroom at Colectivo',
    city: 'Milwaukee, WI',
    with: 'The Nude Party',
  },
  {
    date: '02/14/2024',
    venue: 'The Empty Bottle',
    city: 'Chicago, IL',
    with: 'Yuma Abe',
    // tickets: 'https://www.ticketweb.com/event/yuma-abe-lake-j-empty-bottle-tickets/13287533',
  },
  {
    date: '03/05/2024',
    venue: 'Anatolia',
    city: 'Hanoi, Vietnam',
    with: 'Whitney as backing band',
    tickets: 'https://www.instagram.com/anatolia_in_hanoi/',
  },
  {
    date: '03/15/2024',
    venue: 'Constellation',
    city: 'Chicago, IL',
  },
  {
    date: '08/02/2024',
    venue: 'Postock',
    city: 'Albany, WI',
  },
  {
    date: '08/10/2024',
    venue: 'Thalia Hall',
    city: 'Chicago, IL',
    with: 'Whitney & Hannah Frances',
    tickets: 'https://www.ticketweb.com/event/sold-out-whitney-with-hannah-thalia-hall-tickets/13460544',
  },
  {
    date: '08/11/2024',
    venue: 'Thalia Hall',
    city: 'Chicago, IL',
    with: 'Whitney & Hannah Frances',
    tickets: 'https://www.ticketweb.com/event/whitney-with-hannah-frances-and-thalia-hall-tickets/13479304',
  },
];

export default shows;
