import React from 'react';
import { Link } from 'react-router-dom';

function Nav() {
  return (
    <nav>
      <div className="flex items-center justify-center sm:block font-montserrat font-medium sm:text-biggie uppercase leading-tight tracking-wide -indent-4 ml-4 px-[5vw] my-[3vh] sm:my-[5vh]">
        <ul className="space-y-3">
          <li className="text-center sm:text-left">
            <Link className="hover:pl-4 hover:text-[#ff6305]" to="/lake-j/music">
              Music
            </Link>
            <div className="pl-12 text-xs opacity-60 lowercase invisible sm:visible">
              records i've made or helped make
            </div>
          </li>
          <li className="text-center sm:text-left">
            <Link className="hover:pl-4 hover:text-[#ff6305]" to="/lake-j/shows">
              Shows
            </Link>
            <div className="pl-12 text-xs opacity-60 lowercase invisible sm:visible">
              upcoming and past lake j sightings
            </div>
          </li>
          <li className="text-center sm:text-left">
            <Link className="hover:pl-4 hover:text-[#90C825]" to="/kiwi-house">
              Kiwi House
            </Link>
            <div className="pl-12 text-xs opacity-60 lowercase invisible sm:visible">
              monthly music experiments{' '}
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
