import Header from './components/Header';
import Home from './components/pages/Home';
import LandingPage from './components/pages/LandingPage';
import Footer from './components/Footer';
import Music from './components/pages/Music';
import KiwiHouse from './components/pages/KiwiHouse';
import Shows from './components/pages/Shows';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        {/* if on the landing page route, don't display header */}
        <Header />
        <div className="grow flex flex-col max-w-full">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/lake-j" element={<Home />} />
            <Route path="/lake-j/music" element={<Music />} />
            <Route path="/lake-j/shows" element={<Shows />} />
            <Route path='/kiwi-house' element={<KiwiHouse />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
